<template>
    <div class="stage-pbx">
        <app-loader v-if="loading"></app-loader>
        <app-error v-model="errors.show" :message="errors.message"></app-error>

        <div class="form-container">
            <div class="form-section">
                <div class="form-group">
                    <div class="form-controls">
                        <app-autocomplete
                            label="Pilot Number"

                            :required="true"

                            :options="filtered_phone_numbers"
                            :delay="500"
                            :error="errors.fields.PilotNumber"

                            @typing="filterPhoneNumbers"
                            @select="selectPilotNumber"
                        />
                    </div>

                    <div class="form-controls">
                        <app-input
                            v-model="pbx.AccountNumber"

                            label="Your account number"

                            :required="true"

                            :mask="account_number_mask"

                            :error="errors.fields.AccountNumber"
                            :disabled="loading"

                            @change="errors.fields.AccountNumber = null"
                        />
                    </div>

                    <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import escapeStringRegexp from 'escape-string-regexp'

import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appAutocomplete from '@/components/app-autocomplete'

import SipTrunkValidator from '@/validators/sip-trunk-validator'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        trunk: { type: Object, required: true },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appAutocomplete,
    },

    data() {
        return {
            phone_numbers: [],

            search_query: '',

            pbx: {
                PilotNumber: '',
                AccountNumber: '',
            },

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {
                    PilotNumber:   null,
                    AccountNumber: null,
                },
            },
        }
    },

    methods: {
        init() {
            this.getPhoneNumbers()
        },

        getPhoneNumbers() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                NumberType: 'SIP',

                // 'SearchOptions.PageSize': 100,
                // 'SearchOptions.PageSize': 1000,
                'SearchOptions.PageSize': -1,
            }

            this.$store.dispatch('api_phonenumber/FindAvailablePhoneNumbersByType', params)
                .then(({ AvailablePhoneNumbers }) => {
                    this.phone_numbers = AvailablePhoneNumbers

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.loading = false
                })
        },

        filterPhoneNumbers(search_query) {
            this.search_query = search_query

            this.pbx.PilotNumber = ''
        },

        selectPilotNumber({ value }) {
            this.pbx.PilotNumber = value

            this.errors.fields.PilotNumber = null
        },

        validation() {
            let is_valid = true

            this.errors.fields = {
                PilotNumber:   null,
                AccountNumber: null,
            }

            const fields = {
                PilotNumber:   { rule: 'PhoneNumber',   message: 'Please, select phone number',       },
                AccountNumber: { rule: 'AccountNumber', message: 'Please, enter your account number', },
            }

            for (const key in fields) {
                if (SipTrunkValidator.isRuleExists(fields[key].rule)) {
                    if (SipTrunkValidator.isInvalid(fields[key].rule, this.pbx[key], fields[key].message)) {
                        this.errors.fields[key] = SipTrunkValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                const pbx = { ...this.pbx }

                this.$emit('ready', { pbx })
                this.$emit('go-next')
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        account_number_mask() {
            return {
                regex: '[0-9]*'
            }
        },

        filtered_phone_numbers() {
            let filtered_phone_numbers = []

            if (this.search_query.length > 0) {
                const pattern = new RegExp(escapeStringRegexp(this.search_query), 'gi')

                for (let i = 0, len = this.phone_numbers.length; i < len; i++) {
                    if (pattern.test(this.phone_numbers[i].PhoneNumber)) {
                        filtered_phone_numbers.push(this.phone_numbers[i])
                        pattern.lastIndex = 0
                    }
                }
            } else {
                filtered_phone_numbers = this.phone_numbers
            }

            filtered_phone_numbers = filtered_phone_numbers.map(phone_number => ({
                key:   phone_number.PhoneNumber,
                value: phone_number.PhoneNumber,
            }))

            return filtered_phone_numbers
        },
    }
}
</script>

<style lang="scss">
.stage-pbx {
    max-width: 770px;

    .app-error {
        margin-bottom: 24px;
    }

    .form-container {
        .form-section {
            .form-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .form-controls {
                    width: 100%;
                    max-width: calc(50% - 15px);

                    margin-bottom: 30px;
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .stage-pbx {
        .form-container {
            .form-section {
                .form-group {
                    .form-controls {
                        max-width: 100%;
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
}
</style>