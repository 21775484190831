<template>
    <div class="page-add-trunk">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="back_to">Back to SIP Trunks</router-link>

                    <h1 class="heading">Add Trunk</h1>

                    <div class="trunk-stages">
                        <h3>New Trunk Stages</h3>

                        <div class="stages">
                            <div class="stage" v-for="(stage, key) in stages.list" :key="key"
                                :class="{
                                    [`step-${ key }`]: true,
                                    opened: stage.name == stages.opened,
                                    completed: stage.completed,
                                    current: stage.name == stages.current,
                                }"
                            >
                                <div class="stage-head">
                                    <div class="btn btn-stage btn-stage-point">
                                        <div class="stage-circle">
                                        </div>
                                        <span></span>
                                    </div>
                                    <button class="btn btn-stage btn-stage-expand" @click="toggleStage(stage)" :disabled="!stage.ready">{{ stage.title }}</button>
                                </div>

                                <div class="stage-body">
                                    <component :is="stage.body"
                                        :trunk="trunk"

                                        @ready="onStageReady"
                                        @go-next="goNext"

                                        @confirm="onConfirm"

                                        @created="onCreated"

                                        ref="stage"
                                    ></component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import stageFields from './stages/fields'
import stagePBX from './stages/pbx'
import stageConfirmSettings from './stages/confirm-settings'
import stageSummary from './stages/summary'
import stageWorkingStatus from './stages/working-status'

export default {
    data() {
        return {
            trunk: {
                fields: {},
                pbx: {},
                confirm_settings: {},
                working_status: {},

                created: false,
            },

            stages: {
                opened: 'fields',
                current: 'fields',

                // opened: 'pbx',
                // current: 'pbx',

                // opened: 'confirm-settings',
                // current: 'confirm-settings',

                list: {
                    'fields': {
                        name: 'fields',
                        ready: true,
                        completed: false,
                        title: 'Settings',
                        body: stageFields,
                    },

                    'pbx': {
                        name: 'pbx',
                        ready: false,
                        completed: false,
                        title: 'Pilot Number',
                        body: stagePBX,
                    },

                    'confirm-settings': {
                        name: 'confirm-settings',
                        ready: false,
                        completed: false,
                        title: 'Confirm settings',
                        body: stageConfirmSettings,
                    },

                    'summary': {
                        name: 'summary',
                        ready: false,
                        completed: false,
                        title: 'Summary',
                        body: stageSummary,
                    },

                    'working-status': {
                        name: 'working-status',
                        ready: false,
                        completed: false,
                        title: 'Working status',
                        body: stageWorkingStatus,
                    },
                },
            },
        }
    },

    created() {
        if (this.theme != 'pb') {
            delete this.stages.list['summary']
        }
    },

    methods: {
        onCreated() {
            console.log('onCreated() {')
            console.log('this.trunk.created', this.trunk.created)

            this.trunk.created = true

            console.log('this.trunk.created', this.trunk.created)
        },

        goNext() {
            this.stage.completed = true

            const next_stage_index = this.stages_indexes[this.stages.opened] + 1

            if (next_stage_index < this.stages_sequence.length) {
                this.stages.opened = this.stages_sequence[ next_stage_index ]
                this.stages.current = this.stages.opened

                this.$nextTick(() => {
                    this.$refs.stage[next_stage_index].init()
                })
            }
        },

        onStageReady(trunk) {
            if (trunk) {
                this.trunk = {
                    ...this.trunk,
                    ...trunk,
                }

                this.toggleStageReady(true)
            } else {
                // switch (this.step.name) {}

                this.toggleStageReady(false)
            }
        },

        toggleStageReady(ready) {
            if (ready) {
                const next_stage = this.stages_sequence[ this.stages_indexes[this.stages.opened] + 1 ]

                if (next_stage) {
                    this.stages.list[ next_stage ].ready = true
                }
            } else {
                /*
                    for (let i = this.stages_indexes[this.stages.opened] + 1, len = this.stages_sequence.length; i < len; i++) {
                        const next_stage = this.stages_sequence[i]

                        this.stages.list[ next_stage ].ready = false

                        this.stages.current = this.stages.opened
                    }
                */
            }
        },

        toggleStage(stage, is_not_closed) {
            if (is_not_closed) {
                this.stages.opened = stage.name
            } else {
                this.stages.opened = this.stages.opened == stage.name ? null : stage.name
            }
        },

        onConfirm() {
            this.$router.push({ ...this.back_to })
        },
    },

    computed: {
        ...mapGetters(['theme']),

        back_to() {
            return this.referrer && this.referrer.name == 'sip-trunking-sip-trunks'
                ? this.referrer
                : { name: 'sip-trunking-sip-trunks' }
        },

        stage() {
            return this.stages.list[ this.stages.opened ]
        },

        stages_indexes() {
            let index = 0,
                indexes = {}

            for (const key in this.stages.list) {
                indexes[ key ] = index++
            }

            return indexes
        },

        stages_sequence() {
            return Object.keys(this.stages.list)
        },
    },
}
</script>

<style lang="scss">
.page-add-trunk {
    padding-bottom: 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .trunk-stages {
        position: relative;
        margin: 48px auto 0;
        padding: 24px 24px 32px;
        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-tertiary);

        /* @todo remove this "overflow: hidden;" after making for :before dynamic height */
        overflow: hidden;

        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;
        }

        .stages {
            margin: 32px 0 0 8px;
            border-radius: 0 0 $border-radius-secondary $border-radius-secondary;

            /* @todo make for :before dynamic height */
            // overflow: hidden;

            .btn-stage {
                padding: 0;

                &:not(button) {
                    cursor: default;
                }

                &.btn-stage-point {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    background: transparent;
                    flex-shrink: 0;
                    margin-right: 16px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 2px;
                        height: 1200px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background: var(--color-stages-icons-bg);
                        transform: translate(-50%, 0);
                        pointer-events: none;
                    }

                    .stage-circle {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0.5);

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 100%;
                        height: 100%;

                        background-color: var(--color-stages-icons-bg);

                        border: 2px solid var(--color-stages-icons-bg);

                        border-radius: 50%;

                        pointer-events: none;

                        transition: $transition-duration-primary;
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        width: 24px;
                        height: 24px;

                        color: var(--color-stage-icon);

                        opacity: 0;
                        visibility: hidden;

                        transition: $transition-duration-primary;
                    }

                    &.horizontal {
                        &:before {
                            width: 1000px;
                            height: 2px;
                            transform: translate(0, -50%);
                        }
                    }

                    &[disabled] {
                        opacity: 1;
                    }
                }

                &.btn-stage-expand {
                    width: auto;
                    height: 100%;
                    background: transparent;
                    font-size: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--color-stages-title);
                    text-transform: $stages-btn-text-transform;
                    transition: $transition-duration-primary;

                    &[disabled] {
                        color: var(--color-stages-title);
                        cursor: default;
                    }
                }
            }

            .stage {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;

                    .btn-stage-point {
                        &:before {
                            background: var(--color-component-bg-primary);
                        }
                    }
                }

                .stage-head {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    i {
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        background: none no-repeat center;
                        background-size: contain;
                        flex-shrink: 0;
                    }
                }

                .stage-body {
                    display: none;
                    padding: 24px 0 0 #{ 32px + 16px };
                }

                &.opened {
                    .stage-head {
                        .btn-stage-expand {
                            color: var(--color-stages-title-active);

                            &:after {
                                transform: rotateX(180deg);
                            }
                        }
                    }

                    .stage-body {
                        display: block;
                    }
                }

                &.completed {
                    .btn-stage {
                        &.btn-stage-point {
                            &:before {
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }
                        }

                        &.btn-stage-expand {
                            &:hover {
                                color: var(--color-stages-title-active);
                            }
                        }
                    }
                }

                &.current {
                    .btn-stage {
                        &.btn-stage-point {
                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }
                        }

                        &.btn-stage-expand {
                            color: var(--color-stages-title-active);
                        }
                    }
                }
            }

            .stage {
                &.step-fields {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-stage-fields);
                                }
                            }
                        }
                    }
                }

                &.step-pbx {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-stage-pbx);
                                }
                            }
                        }
                    }
                }

                &.step-confirm-settings {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-stage-confirm-settings);
                                }
                            }
                        }
                    }
                }

                &.step-summary {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-stage-summary);
                                }
                            }
                        }
                    }
                }

                &.step-working-status {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-stage-working-status);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-add-trunk {
        .trunk-stages {
            padding: 24px 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-add-trunk {
        .trunk-stages {
            margin-top: 32px;
            padding: 16px 16px 16px 8px;

            h3 {
                font-size: 24px;
                line-height: 32px;
            }

            .stages {
                margin: 20px 0 0 0;

                .btn-stage {
                    &.btn-stage-point {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;

                        span {
                            transform: translate(-50%, -50%) scale(0.8);
                        }
                    }

                    &.btn-stage-expand {
                        font-size: 16px;
                    }
                }

                .stage {
                    .stage-body {
                        padding-left: 26px;

                        .app-input {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>