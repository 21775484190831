<template>
    <div class="stage-fields">
        <app-loader v-if="loading" />
        <app-error v-model="errors.show" :message="errors.message" />

        <div class="form-container">
            <div class="form-section">
                <div class="form-group">
                    <div class="form-controls">
                        <app-input
                            v-model="fields.NumberSIPChannels"

                            label="Number of SIP Channels"

                            type="number"

                            :required="true"

                            :error="errors.fields.NumberSIPChannels"
                            :disabled="loading"

                            @change="errors.fields.NumberSIPChannels = null"
                        />

                        <app-textarea
                            v-model="fields.Description"

                            label="Description"

                            :required="true"

                            :error="errors.fields.Description"
                            :disabled="loading"

                            @change="errors.fields.Description = null"
                        />

                        <app-radio-buttons
                            v-model="fields.Registered"
                            :items="connection_options"

                            :inline="true"
                        />

                        <template v-if="is_authentication">
                            <app-input
                                v-model="fields.Username"

                                label="Username"

                                :required="true"

                                :autocomplete="false"

                                :error="errors.fields.Username"
                                :disabled="loading"

                                @change="errors.fields.Username = null"
                            />

                            <app-input
                                v-model="fields.Password"

                                label="Password"

                                type="password"

                                :required="true"

                                :autocomplete="false"

                                :password-helper="true"
                                :password-helper-rules="password_helper_rules"

                                :with-toggle-password-visibility="true"

                                :error="errors.fields.Password"
                                :disabled="loading"

                                @change="errors.fields.Password = null"
                            />
                        </template>
                        <template v-else>
                            <app-input
                                v-model="fields.IPAddress"

                                label="IP Address"

                                :required="true"

                                :error="errors.fields.IPAddress"
                                :disabled="loading"

                                @change="errors.fields.IPAddress = null"
                            />

                            <app-checkbox v-model="fields.secondary_endpoint" :disabled="loading">Add secondary endpoint</app-checkbox>

                            <template v-if="is_secondary_endpoint">
                                <app-input
                                    v-model="fields.SecondaryIPAddress"

                                    label="Secondary IP Address"

                                    :required="true"

                                    :error="errors.fields.SecondaryIPAddress"
                                    :disabled="loading"

                                    @change="errors.fields.SecondaryIPAddress = null"
                                />

                                <app-dropdown-select
                                    v-model="fields.Method"
                                    :options="method_options"

                                    key-value="value"
                                    key-title="title"

                                    label="Method"

                                    :error="errors.fields.Method"
                                    :disabled="loading"

                                    @change="errors.fields.Method = null"
                                />
                            </template>
                        </template>

                        <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appCheckbox from '@/components/app-checkbox'
import appRadioButtons from '@/components/app-radio-buttons'
import appDropdownSelect from '@/components/app-dropdown-select'

import SipTrunkValidator from '@/validators/sip-trunk-validator'

import errMessage from '@/helpers/errMessage'

const CUSTOM_PASSWORD_RULES = [
    {
        pattern: /^.{20,}/,
        message: 'Password must be at least 20 characters long',
    },
    {
        pattern: /[A-Z]/,
        message: 'Password must contain an upper case character',
    },
    {
        pattern: /[a-z]/,
        message: 'Password must contain a lower case character',
    },
    {
        pattern: /[0-9]/,
        message: 'Password must contain a digit',
    },
    {
        pattern: /[@#\$%{}\[\]\(\)\/\\\|'"`~,;:\.<>\^\?\*\+!&]/,
        message: 'Password must contain a special character',
    },
]

export default {
    props: {
        trunk: { type: Object, required: true },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appCheckbox,
        appRadioButtons,
        appDropdownSelect,
    },

    data() {
        return {
            fields: {
                Description: '',

                Registered: true,

                Username: '',
                Password: '',

                IPAddress: '',
                secondary_endpoint: false,
                SecondaryIPAddress: '',
                Method: 'UNKNOWN',

                NumberSIPChannels: '10',
            },

            connection_options: {
                authentication: {
                    value: true,
                    label: 'Authentication',
                },

                static_endpoint_ip: {
                    value: false,
                    label: 'Static Endpoint IP',
                },
            },

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        init() {},

        validation() {
            let is_valid = true

            this.errors.fields = {}

            let fields = {
                NumberSIPChannels: { rule: 'NumberSIPChannels', message: 'Please, enter valid Number of SIP Channels'     },
                Description:       { rule: 'Description',       message: 'Description must contain at least 5 characters' },
            }

            if (this.is_authentication) {
                SipTrunkValidator.mixin({
                    Password: {
                        customPasswordCheck: true,

                        customPasswordRules: CUSTOM_PASSWORD_RULES,
                    },
                })

                fields = {
                    ...fields,

                    Username: { rule: 'Username', message: 'Please, enter username' },
                    Password: { rule: 'Password', message: 'Please, enter password' },
                }
            } else {
                fields = {
                    ...fields,

                    IPAddress: { rule: 'IPAddress', message: 'Please, enter valid IPv4 address' },
                }

                if (this.is_secondary_endpoint) {
                    fields = {
                        ...fields,

                        SecondaryIPAddress: { rule: 'IPAddress', message: 'Please, enter valid IPv4 address' },
                    }
                }
            }

            for (const key in fields) {
                if (SipTrunkValidator.isRuleExists(fields[key].rule)) {
                    if (SipTrunkValidator.isInvalid(fields[key].rule, this.fields[key], fields[key].message)) {
                        this.errors.fields[key] = SipTrunkValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                const fields = { ...this.fields }

                this.$emit('ready', { fields })
                this.$emit('go-next')
            }
        },
    },

    computed: {
        is_authentication() {
            return this.fields.Registered
        },

        is_secondary_endpoint() {
            return this.fields.secondary_endpoint
        },

        method_options() {
            return [
                {
                    value: 'UNKNOWN',
                    title: 'Unknown',
                },
                {
                    value: 'LOAD_BALANCE',
                    title: 'Load balance',
                },
                {
                    value: 'FAILOVER',
                    title: 'Failover',
                },
            ]
        },

        password_helper_rules() {
            return [
                {
                    pattern: /^.{20,}/,
                    message: '20 characters',
                },
                {
                    pattern: /[A-Z]/,
                    message: '1 upper case',
                },
                {
                    pattern: /[a-z]/,
                    message: '1 lower case',
                },
                {
                    pattern: /[0-9]/,
                    message: '1 digit',
                },
                {
                    pattern: /[@#\$%{}\[\]\(\)\/\\\|'"`~,;:\.<>\^\?\*\+!&]/,
                    message: '1 special character',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.stage-fields {
    max-width: 770px;

    .app-error {
        margin-bottom: 24px;
    }

    .form-container {
        .form-section {
            .form-group {
                .form-controls {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .app-textarea {
                        height: 106px;
                    }

                    .app-input,
                    .app-textarea,
                    .app-checkbox,
                    .app-radio-buttons,
                    .app-dropdown-select {
                        margin-bottom: 30px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .app-checkbox {
                        align-items: center;
                    }

                    .app-checkbox,
                    .app-radio-buttons {
                        width: 100%;
                    }

                    .app-input,
                    .app-checkbox,
                    .app-dropdown-select {
                        max-width: calc(50% - 15px);
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .stage-fields {
        .form-container {
            .form-section {
                .form-group {
                    .form-controls {
                        .app-input,
                        .app-textarea,
                        .app-checkbox,
                        .app-radio-buttons,
                        .app-dropdown-select {
                            margin-bottom: 24px;
                        }

                        .app-input,
                        .app-checkbox,
                        .app-dropdown-select {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
</style>