<template>
    <div class="stage-confirm-settings">
        <app-loader v-if="loading"></app-loader>
        <app-error v-model="errors.show" :message="errors.message"></app-error>

        <div class="form-container">
            <div class="form-section">
                <div class="form-group">
                    <div class="form-controls">
                        <div class="details">
                            <div class="detail">
                                <div class="title">Description:</div>
                                <div class="value">{{ description }}</div>
                            </div>

                            <template v-if="is_authentication">
                                <div class="detail">
                                    <div class="title">Username:</div>
                                    <div class="value">{{ username }}</div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="detail">
                                    <div class="title">IP address:</div>
                                    <div class="value">{{ ip_address }}</div>
                                </div>
                            </template>

                            <div class="detail">
                                <div class="title">Pilot Number:</div>
                                <div class="value">{{ pilot_number }}</div>
                            </div>

                            <div class="detail">
                                <div class="title">Your account number:</div>
                                <div class="value">{{ account_number }}</div>
                            </div>
                        </div>

                        <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appCheckbox from '@/components/app-checkbox'
import appRadioButtons from '@/components/app-radio-buttons'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        trunk: { type: Object, required: true },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appCheckbox,
        appRadioButtons,
        appDropdownSelect,
    },

    data() {
        return {
            confirm_settings: {},

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        init() {},

        confirm() {
            console.log('confirm() {')
            console.log('this.trunk.created', this.trunk.created)

            if (this.trunk.created) {
                console.log('go-next')
                this.$emit('go-next')
            } else {
                this.loading = true

                let params = {
                    SPID: this.current_spid,

                    Description: this.trunk.fields.Description,

                    Registered: this.trunk.fields.Registered,

                    Channels: this.trunk.fields.NumberSIPChannels,

                    Username: this.trunk.pbx.PilotNumber,

                    PhoneNumbers: [
                        this.trunk.pbx.PilotNumber,
                    ],

                    PilotAccountNumber: this.trunk.pbx.AccountNumber,
                }

                if (this.is_authentication) {
                    params = {
                        ...params,

                        EndpointUsername: this.trunk.fields.Username,
                        EndpointPassword: this.trunk.fields.Password,
                    }
                } else {
                    params = {
                        ...params,

                        EndpointIPAddress: this.trunk.fields.IPAddress,
                    }

                    if (this.is_secondary_endpoint) {
                        params = {
                            ...params,

                            EndpointIPAddressSecondary: this.trunk.fields.SecondaryIPAddress,
                            EndpointMethod: this.trunk.fields.Method,
                        }
                    }
                }

                this.$store.dispatch('api_siptrunk/AddSIPTrunk', params)
                    .then(response => {
                        console.log('response', response)

                        this.errors.show = false
                        this.errors.message = ''

                        this.loading = false

                        const confirm_settings = { ...this.confirm_settings }

                        this.$emit('created')

                        this.$emit('ready', { confirm_settings })
                        this.$emit('go-next')
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        is_authentication() {
            return this.trunk.fields.Registered
        },

        is_secondary_endpoint() {
            return this.trunk.fields.secondary_endpoint
        },

        description() {
            return this.trunk.fields.Description ? this.trunk.fields.Description : '?'
        },

        username() {
            return this.trunk.fields.Username ? this.trunk.fields.Username : '?'
        },

        ip_address() {
            return this.trunk.fields.IPAddress ? this.trunk.fields.IPAddress : '?'
        },

        pilot_number() {
            return this.trunk.pbx.PilotNumber ? `DDI ${ this.trunk.pbx.PilotNumber }` : '?'
        },

        account_number() {
            return this.trunk.pbx.AccountNumber ? this.trunk.pbx.AccountNumber : '?'
        },
    },
}
</script>

<style lang="scss">
.stage-confirm-settings {
    max-width: 770px;

    .app-error {
        margin-bottom: 24px;
    }

    .form-container {
        .form-section {
            .form-group {
                .form-controls {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .app-textarea {
                        height: 106px;
                    }

                    .app-input,
                    .app-textarea,
                    .app-checkbox,
                    .app-radio-buttons,
                    .app-dropdown-select {
                        margin-bottom: 30px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .app-input,
                    .app-checkbox,
                    .app-dropdown-select {
                        max-width: calc(50% - 15px);
                    }

                    .details {
                        width: 100%;
                        margin-bottom: 30px;

                        .detail {
                            display: flex;

                            margin-bottom: 16px;

                            .title {
                                min-width: 170px;
                                font-size: 18px;
                                font-weight: bold;
                                color: var(--color-text-primary);
                            }

                            .value {
                                padding-left: 32px;
                                color: var(--color-text-primary);
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .stage-confirm-settings {}
}

@media (max-width: $mobile-size) {
    .stage-confirm-settings {
        .form-container {
            .form-section {
                .form-group {
                    .form-controls {
                        .app-input,
                        .app-textarea,
                        .app-checkbox,
                        .app-radio-buttons,
                        .app-dropdown-select {
                            margin-bottom: 24px;
                        }

                        .app-input,
                        .app-checkbox,
                        .app-dropdown-select {
                            max-width: 100%;
                        }

                        .details {
                            .detail {
                                flex-direction: column;

                                .value {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>