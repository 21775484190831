<template>
    <div class="stage-summary">
        <app-loader v-if="loading"></app-loader>
        <app-error v-model="errors.show" :message="errors.message"></app-error>

        <div class="form-container">
            <div class="form-section">
                <div class="form-group">
                    <div class="form-controls">
                        <div class="details">
                            <div class="detail">
                                <div class="title">Pilot Number:</div>
                                <div class="value">{{ pilot_number }}</div>
                            </div>

                            <template v-if="is_authentication">
                                <div class="detail">
                                    <div class="title">Username:</div>
                                    <div class="value">{{ username }}</div>
                                </div>

                                <div class="detail">
                                    <div class="title">Password:</div>
                                    <app-input
                                        v-model="this.trunk.fields.Password"

                                        type="password"

                                        :readonly="true"
                                        :with-toggle-password-visibility="true"
                                    />
                                </div>
                            </template>
                            <template v-else>
                                <div class="detail">
                                    <div class="title">IP address:</div>
                                    <div class="value">{{ ip_address }}</div>
                                </div>
                                <div v-if="ip_address_secondary" class="detail">
                                    <div class="title">Secondary IP address:</div>
                                    <div class="value">{{ ip_address_secondary }}</div>
                                </div>
                            </template>

                            <div class="detail">
                                <div class="title">Your account number:</div>
                                <div class="value">{{ account_number }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-section server-static-details">
                <div class="form-title">
                    PLAN B SIP server static details
                </div>

                <div class="form-group">
                    <div class="form-controls">
                        <div class="details">
                            <div class="detail">
                                <div class="title">DNS SRV:</div>
                                <div class="value">{{summary_default.dns_srv}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">DNS:</div>
                                <div class="value">{{summary_default.dns}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">IPNZ1:</div>
                                <div class="value">{{summary_default.ipnz1}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">IPNZ2:</div>
                                <div class="value">{{summary_default.ipnz2}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">IPAU1:</div>
                                <div class="value">{{summary_default.ipau1}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">IPAU2:</div>
                                <div class="value">{{summary_default.ipau2}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-controls">
                        <div class="details details-default">
                            <div class="detail">
                                <div class="title">Default Signalling Port:</div>
                                <div class="value">{{summary_default.default_signalling_port}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">Destination Media Port range:</div>
                                <div class="value">{{summary_default.destination_media_port_range}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">Supported Codecs:</div>
                                <div class="value">{{summary_default.supported_codecs}}</div>
                            </div>
                            <div class="detail">
                                <div class="title">Default Dtmf:</div>
                                <div class="value">{{summary_default.default_dtmf}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-controls">
                        <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import errMessage from '@/helpers/errMessage'

const SUMMARY_DEFAULT = {
    dns_srv: 'sip.solarix.net.nz',
    dns: 'sbc.solarix.net.nz',
    ipnz1: '43.255.163.25',
    ipnz2: '43.255.163.26',
    ipau1: '120.136.60.249',
    ipau2: '120.136.60.250',
    default_signalling_port: '5060 TCP or UDP',
    destination_media_port_range: '15000:30000 UDP',
    supported_codecs: 'G711a, G711u, G729a',
    default_dtmf: 'RFC2833',
}

export default {
    props: {
        trunk: { type: Object, required: true },
    },

    components: {
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            confirm_settings: {},

            loading: false,

            summary_default: SUMMARY_DEFAULT,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        init() {},

        confirm() {
            const confirm_settings = { ...this.confirm_settings }

            this.$emit('ready', { confirm_settings })
            this.$emit('go-next')
        },
    },

    computed: {
        is_authentication() {
            return this.trunk.fields.Registered
        },

        is_secondary_endpoint() {
            return this.trunk.fields.secondary_endpoint
        },

        description() {
            return this.trunk.fields.Description ? this.trunk.fields.Description : '?'
        },

        username() {
            return this.trunk.fields.Username ? this.trunk.fields.Username : '?'
        },

        ip_address() {
            return this.trunk.fields.IPAddress ? this.trunk.fields.IPAddress : '?'
        },

        ip_address_secondary() {
            return this.trunk.fields.SecondaryIPAddress
        },

        pilot_number() {
            return this.trunk.pbx.PilotNumber ? `DDI ${ this.trunk.pbx.PilotNumber }` : '?'
        },

        account_number() {
            return this.trunk.pbx.AccountNumber ? this.trunk.pbx.AccountNumber : '?'
        },
    },
}
</script>

<style lang="scss">
.stage-summary {
    max-width: 100%;

    .app-error {
        margin-bottom: 24px;
    }

    .form-title {
        font-size: 20px;
        color: var(--color-stages-title-active);
        text-transform: $stages-btn-text-transform;

        margin-bottom: 16px;
    }

    .form-container {
        .form-section {
            .form-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .form-controls {
                    width: 100%;
                    max-width: calc(50% - 15px);

                    margin-bottom: 30px;

                    .details {
                        width: 100%;
                        .detail {
                            display: flex;
                            align-items: center;

                            margin-bottom: 16px;

                            .title {
                                min-width: 170px;
                                font-size: 18px;
                                font-weight: bold;
                                margin-right: 32px;
                                color: var(--color-text-primary);
                            }

                            .value {
                                color: var(--color-text-primary);
                            }

                            .app-input {
                                max-width: 370px;

                                input {
                                    opacity: 0.4;
                                    border-color: var(--color-input-border-inactive);
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        &.details-default {
                            .detail {
                                .title {
                                    min-width: 240px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .stage-summary {
        .form-container {
            .form-section {
                .form-group {
                    .form-controls {
                        width: 100%;
                        max-width: 435px;
                    }
                }

                &.server-static-details {
                    .form-group {
                        .form-controls {
                            .details {
                                .title {
                                    min-width: 240px;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: $mobile-size) {
    .stage-summary {
        .form-title {
            // font-size: 16px;
        }

        .form-container {
            .form-section {
                .form-group {
                    .form-controls {
                        .details {
                            .detail {
                                flex-direction: column;
                                align-items: flex-start;

                                margin-bottom: 8px;

                                .title {
                                    margin-bottom: 4px;
                                }

                                .value {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>