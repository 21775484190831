<template>
    <div class="stage-working-status">
        <app-loader v-if="loading"></app-loader>
        <app-error v-model="errors.show" :message="errors.message"></app-error>

        <div class="form-container">
            <div class="form-section">
                <div class="form-group">
                    <div class="form-controls">
                        <p>I have completed the configuration for this SIP Trunk, I can confirm that the SIP trunk is working. I accept the billing for services on this SIP Trunk.</p>

                        <app-checkbox v-model="is_tested" :disabled="loading">I confirm that this SIP Trunk is tested as working</app-checkbox>

                        <button class="btn btn-primary btn-small" :disabled="!is_tested" @click="confirm">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appCheckbox from '@/components/app-checkbox'
import appRadioButtons from '@/components/app-radio-buttons'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        trunk: { type: Object, required: true },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appCheckbox,
        appRadioButtons,
        appDropdownSelect,
    },

    data() {
        return {
            is_tested: false,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        init() {},

        validation() {
            let is_valid = true

            this.errors = {}

            const fields = {
                /**
                 * @todo
                 */
            }

            for (const key in fields) {
                /**
                 * @todo
                 */
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.$emit('confirm')
            }
        },
    },
}
</script>

<style lang="scss">
.stage-working-status {
    max-width: 770px;

    .app-error {
        margin-bottom: 24px;
    }

    .form-container {
        .form-section {
            .form-group {
                .form-controls {
                    .app-checkbox {
                        margin-bottom: 30px;
                    }

                    p {
                        margin-bottom: 16px;
                        color: var(--color-text-primary);
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .stage-working-status {}
}

@media (max-width: $mobile-size) {
    .stage-working-status {
        .form-container {
            .form-section {
                .form-group {
                    .form-controls {
                        .app-checkbox {
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
    }
}
</style>